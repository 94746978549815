import React from "react";

import Layout from "../../layout/Layout";
import StyledLink from "../../components/styled-link/StyledLink";
import AgenturaImage from "./assets/AgenturaImage";

import agenturaStyle from "./assets/Agentura.module.scss";

const AgenturaPage = () => {
  return (
    <Layout title="Agentura">
      <section name="agency" className={agenturaStyle["agency"]}>
        <div className={agenturaStyle["agency_container"]}>
          <div className={agenturaStyle["agency_title"]}>
            <h1>Delta Weddings</h1>
          </div>
          <div className={agenturaStyle["agency_content"]}>
            <p>
              Chcete si svůj den užít na maximum a mít minimum starostí? Pak je
              tu pro vás svatební koordinátorka, která vám zajistí svatbu od A
              až do Z.
            </p>
            <p>
              Nabízíme:
              <br />
              - kompletní koordinaci a přípravu svatby
              <br />
              - koordinace svatebního dne
              <br />
              - koordinace svatebního obřadu
              <br />- dekorace a výzdoba svateb
            </p>
            <p>
              <StyledLink
                href="https://www.facebook.com/Delta-Weddings-Koordinace-svateb-444298402793811/"
                target="_blank"
              >
                Facebook
              </StyledLink>
              <br />
              <StyledLink
                href="https://www.instagram.com/delta.weddings"
                target="_blank"
              >
                Instagram
              </StyledLink>
            </p>
          </div>
          <div className={agenturaStyle["agency_image"]}>
            <AgenturaImage />
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default AgenturaPage;
